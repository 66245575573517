
import { defineComponent } from "@vue/runtime-core"
// import CardBorderedBody from "@/components/CardBorderedBody.vue"
// import CardBordered from "@/components/CardBordered.vue"
// import TextSnippet from "@/components/TextSnippet.vue"
import LegalHeader from "@/components/legal/LegalHeader.vue"
import TestSystemContainer from "@/components/TestSystemContainer.vue"

export default defineComponent({
  name: "Privacy",
  components: {
    // CardBorderedBody,
    // CardBordered,
    // TextSnippet,
    LegalHeader,
    TestSystemContainer,
  },
})
