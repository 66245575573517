
import { defineComponent } from "@vue/runtime-core"
import moment from "moment"

export default defineComponent({
  props: {
    heading: {
      type: String,
      default: (): string => "",
    },
    stand: {
      type: Boolean,
      default: (): boolean => false,
    },
  },
  setup() {
    return {
      moment,
    }
  },
})
